import * as React from 'react';
import { Grid } from '@mui/material/';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import '../styles/app.css';
export default function Contact() {
  return (
    <Grid
      container
      xs={12}
      sx={{
        marginTop: 5,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Grid
        item
        md={12}
        xs={12}
        style={{ textAlign: 'center' }}
        className='title'
        id='contact_section'
      >
        Kontakt
      </Grid>
      <Grid
        container
        style={{
          justifyContent: 'center',
          textAlign: 'left',
          marginTop: '20px',
        }}
      >
        <Grid
          container
          md={12}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <Grid item xs={12} md={12}>
            <a
              href='https://www.instagram.com/amed.centrum/'
              target='blank'
              style={{ textDecoration: 'none' }}
            >
              <p
                className='title'
                style={{
                  fontWeight: '300',
                  textAlign: 'center',
                  fontSize: '1rem',
                }}
              >
                Dostępna wyłącznie rejestracja telefoniczna
              </p>
              <p
                className='title'
                style={{
                  fontWeight: '300',
                  textAlign: 'center',
                  fontSize: '1rem',
                }}
              >
                Kolejne terminy ustalamy telefonicznie
              </p>
            </a>
          </Grid>
        </Grid>

        <Grid
          container
          md={5}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <Grid
            md={3}
            xs={5}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LocationOnIcon fontSize='large' style={{ color: '#0a4870' }} />
          </Grid>

          <Grid item xs={7} md={9}>
            <a
              target='blank'
              style={{ textDecoration: 'none' }}
              href='https://www.google.com/maps/dir//%C5%81apanowska+119,+32-420+Gd%C3%B3w/@49.90494,20.1229803,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47163f8e277731b5:0xbf2f315ced010630!2m2!1d20.2053326!2d49.9048923?entry=ttu&g_ep=EgoyMDI0MTAwNy4xIKXMDSoASAFQAw%3D%3D'
            >
              <p
                className='contact_content'
                style={{ color: '#363636', fontWeight: '300' }}
              >
                Amed Centrum Dobrych Zmian
              </p>
              <p
                className='contact_content'
                style={{ color: '#363636', fontWeight: '300' }}
              >
                ul. Łapanowska 119
              </p>
              <p
                className='contact_content'
                style={{ color: '#363636', fontWeight: '300' }}
              >
                32-420 Gdów
              </p>
            </a>
          </Grid>
        </Grid>

        <Grid
          container
          md={5}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <Grid
            md={2}
            xs={5}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AccessTimeIcon fontSize='large' style={{ color: '#0a4870' }} />
          </Grid>
          <Grid item xs={7} md={10} style={{ textAlign: 'left' }}>
            <Grid container gap={2}>
              <Grid item md={6}>
                <p
                  className='contact_content'
                  style={{ color: '#363636', fontWeight: '300' }}
                >
                  poniedziałek - piątek
                </p>
                <p
                  className='contact_content'
                  style={{ color: '#363636', fontWeight: '300' }}
                >
                  sobota
                </p>
              </Grid>
              <Grid item md={5}>
                <p
                  className='contact_content'
                  style={{ color: '#363636', fontWeight: '300' }}
                >
                  8-20
                </p>
                <p
                  className='contact_content'
                  style={{ color: '#363636', fontWeight: '300' }}
                >
                  8-17
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          md={5}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <Grid
            md={3}
            xs={5}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CallIcon fontSize='large' style={{ color: '#0a4870' }} />
          </Grid>
          <Grid item xs={7} md={9}>
            <a style={{ textDecoration: 'none' }} href='tel:48535770997'>
              <p
                className='contact_content'
                style={{ color: '#363636', fontWeight: '300' }}
              >
                +48 535 770 997 (pon - pt: 8-15)
              </p>
            </a>
          </Grid>
        </Grid>

        <Grid
          container
          md={5}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <Grid
            md={2}
            xs={5}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MailIcon fontSize='large' style={{ color: '#0a4870' }} />
          </Grid>
          <Grid item xs={7} md={10}>
            <a
              style={{ textDecoration: 'none' }}
              href='mailto:amedcentrum119@gmail.com'
            >
              <p
                className='contact_content'
                style={{ fontWeight: '300', color: '#363636' }}
              >
                amedcentrum119@gmail.com
              </p>
            </a>
          </Grid>
        </Grid>

        <Grid
          container
          md={5}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <Grid
            md={3}
            xs={5}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <InstagramIcon fontSize='large' style={{ color: '#0a4870' }} />
          </Grid>
          <Grid item xs={7} md={9}>
            <a
              href='https://www.instagram.com/amed.centrum/'
              target='blank'
              style={{ textDecoration: 'none' }}
            >
              <p
                className='contact_content'
                style={{ color: '#363636', fontWeight: '300' }}
              >
                amed.centrum
              </p>
            </a>
          </Grid>
        </Grid>

        <Grid
          container
          md={5}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <Grid
            md={2}
            xs={5}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FacebookIcon fontSize='large' style={{ color: '#0a4870' }} />
          </Grid>
          <Grid item xs={7} md={10}>
            <a
              href='https://www.facebook.com/p/AMED-Centrum-Dobrych-Zmian-100087264890092/?locale=pl_PL'
              target='blank'
              style={{ textDecoration: 'none' }}
            >
              <p
                className='contact_content'
                style={{ color: '#363636', fontWeight: '300' }}
              >
                Amed Centrum Dobrych Zmian
              </p>{' '}
            </a>
            {/* Replace with the correct email */}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={11} md={11} style={{ padding: '15px' }}>
        <iframe
          title='Amed'
          width='100%'
          height='400'
          frameBorder='0'
          scrolling='no'
          src='https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=pl&amp;q=ul.%20%C5%81apanowska%20119%2032-320%20Gd%C3%B3w+(AMED)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'
        ></iframe>
      </Grid>
    </Grid>
  );
}
