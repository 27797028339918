import * as React from 'react';
import { useState } from 'react';
import { Grid } from '@mui/material/';
// import Booking from './Booking_Phone';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import MoreOffer from './MoreOffer';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ListComponent from '../Components/ListComponent';
import '../styles/app.css';
import '../styles/offer.scss';
import Booking from './Booking_Phone';

export default function Offer() {
  const [selectedOffer, setSelectedOffer] = useState<Offer | null>(null);
  const [selectedOfferMore, setSelectedOfferMore] = useState<Offer | null>(
    null
  );
  const handleClick = (offer: Offer) => setSelectedOffer(offer);
  const handleClose = () => setSelectedOffer(null);
  const handleClickMore = (offer: Offer) => setSelectedOfferMore(offer);
  const handleCloseMore = () => setSelectedOfferMore(null);
  return (
    <Grid>
      <Grid
        item
        md={12}
        xs={12}
        style={{ textAlign: 'center' }}
        className='title'
        id='offer_section'
      >
        Oferta
      </Grid>
      <Grid
        xs={12}
        container
        className='offer_container'
        sx={{
          alignItems: 'top',
          justifyContent: 'center',
        }}
      >
        {offers
          .sort((a, b) => a.id - b.id)
          .map((offer) => (
            <Grid xs={12} md={4} key={offer.id} style={{ maxWidth: '500px' }}>
              <ul>
                <li
                  className='booking-card shadow'
                  style={{
                    backgroundImage: `url(${offer.imageUrl})`,
                  }}
                >
                  <div className='book-container'>
                    <div className='content'>
                      <button
                        onClick={() => handleClick(offer)}
                        className='btn'
                      >
                        Umów
                      </button>
                    </div>
                  </div>
                  <div className='informations-container'>
                    <h4 className='title_info'>{offer.name}</h4>
                    <p className='sub-title'>
                      <p
                        style={{
                          cursor: 'pointer',
                          textDecorationLine: 'underline',
                        }}
                        onClick={() => handleClickMore(offer)}
                      >
                        Dowiedz się więcej
                      </p>
                    </p>
                    <p className='price'>
                      <CreditScoreIcon />
                      {offer.id === 2 ? 'Od ' : ''}
                      {offer.price} PLN
                    </p>
                    <div className='more-information'>
                      <div className='info-and-date-container'>
                        <div className='box info'>
                          <AccessTimeIcon />
                          <p>Czas trwania {offer.time} min</p>
                        </div>
                        <div className='box date'>
                          <CheckCircleOutlineIcon />
                          <p>Dostępne terminy</p>
                        </div>
                      </div>
                      <div
                        className='summary'
                        style={{
                          overflow: 'hidden',
                          fontSize: '1rem',
                        }}
                      >
                        <p>{offer.summary}</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </Grid>
          ))}
      </Grid>
      {selectedOffer && <Booking offer={selectedOffer} onClose={handleClose} />}
      {selectedOfferMore && (
        <MoreOffer offer={selectedOfferMore} onClose={handleCloseMore} />
      )}
    </Grid>
  );
}

const offers: Offer[] = [
  {
    id: 7,
    name: 'Interwencja kryzysowa',
    summary:
      'Interwencja kryzysowa to doraźna, ale natychmiastowa pomoc psychologiczna lub pedagogiczna.  Interwencja służy przywróceniu równowagi psychicznej, z którą można samodzielnie podjąć dalsze, planowe działania na rzecz pokonania kryzysu.',
    description: (
      <>
        <p>
          Interwencja kryzysowa to doraźna, ale natychmiastowa pomoc
          psychologiczna lub pedagogiczna. Interwencja służy przywróceniu
          równowagi psychicznej, z którą można samodzielnie podjąć dalsze,
          planowe działania na rzecz pokonania kryzysu.
        </p>

        <div style={{ marginBottom: '1rem' }}>
          <Grid container style={{ marginBottom: '20px' }}>
            <h4>Czym jest kryzys?</h4>
            <h4>
              Pojęcie kryzysu jest bardzo pojemne, więc warto zdefiniować je w
              odniesieniu do interwencji kryzysowej. Dotyczy ona zdarzeń:
            </h4>
          </Grid>{' '}
          <ListComponent
            icon={null}
            key={1}
            list={[
              'Nagłych, nieoczekiwanych.',
              'Wywołujących silny stres i skrajne emocje.',
              'Godzących w poczucie bezpieczeństwa.',
              'Uniemożliwiających zaspokojenie istotnych potrzeb.,',
              'Uderzających w najwyższe wartości danej osoby.',
              'Przerastających jej aktualne możliwości adaptacyjne.',
            ]}
          />
        </div>
        <p>
          Kryzysy są naturalnym zjawiskiem – każdy doświadcza ich w życiu wiele
          razy. Nie zawsze jednak mamy odpowiednie zasoby – np. wsparcie
          przyjaciół – by konstruktywnie sobie z nimi poradzić. Tymczasem w
          przypadkach silnego zachwiania równowagi psychofizycznej nikt nie
          powinien pozostać bez pomocy. Trwanie w kryzysie i poczucie braku
          perspektyw na jego pokonanie może mieć poważne konsekwencje dla
          zdrowia psychicznego, a nawet prowadzić do desperackich, tragicznych w
          skutkach zachowań. Takim sytuacjom może zapobiec szybko podjęta
          interwencja kryzysowa.
        </p>
      </>
    ),
    price: 180,
    imageUrl: require('../uploads/Offer/therapy_8.jpg'),
    time: 50,
  },
  {
    id: 10,
    name: 'Grupy wsparcia',
    summary:
      'Podstawą działania grup wsparcia jest dzielenie się wspólnymi doświadczeniami i problemami. Wszystko to dzieje się w bezpiecznym, empatycznym i nieoceniającym środowisku.',
    description: (
      <>
        <p>
          Podstawą działania grup wsparcia jest dzielenie się wspólnymi
          doświadczeniami i problemami. Wszystko to dzieje się w bezpiecznym,
          empatycznym i nieoceniającym środowisku. Na samym początku mogą
          pojawić się problemy z rozmawianiem z obcymi ludźmi, dzieleniem się z
          nimi doświadczeniami, a także przekazywaniem osobistych informacji.
          Lęk przed oceną może wyzwalać niezwykle silne, czasami również
          negatywne uczucia.
        </p>

        <div style={{ marginBottom: '1rem' }}>
          <Grid container style={{ marginBottom: '20px' }}>
            <h4>Jakie korzyści daje grupa wsparcia?</h4>
          </Grid>
          <ListComponent
            icon={null}
            key={2}
            list={[
              'Zrozumienie ze strony innych ludzi i emocjonalne wsparcie.',
              'Możliwość rozładowania swoich negatywnych uczuć w sposób kontrolowany i akceptowalny społecznie.',
              'Poznanie ludzi o podobnych doświadczeniach.',
              'Zrozumienie, w jaki sposób radzić sobie z problemami.',
              'Zapanowanie nad swoim losem.',
              'Poznanie ludzi, którzy mogą służyć jako wsparcie.',
            ]}
          />
        </div>
        <p>
          Grupy wsparcia działają nie w ramach modelu naukowego czy też
          empirycznego, lecz w sposób praktyczny, oparty na konkretnych
          zdarzeniach i doświadczeniach, dlatego są tak skuteczne. Zwykle jednak
          po kilku spotkaniach znacznie łatwiej można rozmawiać o swoich
          problemach i emocjach. Spore znaczenie ma tutaj znalezienie osób o
          podobnych doświadczeniach i poglądach, rozumiejących damskie lub
          męskie emocje. Zwykle w grupach wsparcia bierze udział od kilku do
          kilkunastu osób, a cały proces wspiera terapeuta rozumiejący konkretne
          trudności, z którymi mierzą się na co dzień członkowie społeczności.
          Potrafi też przeanalizować cały proces grupowy i wesprzeć poszczególne
          osoby na różnych etapach drogi ku zdrowieniu lub radzeniu sobie z
          trudnościami.
        </p>
        <p>
          Niezwykłym wsparciem w rozumieniu innych ludzi jest inteligencja
          emocjonalna, która umożliwia postawienie się w sytuacji drugiego
          człowieka. Terapeuci zwykle dysponują nie tylko wiedzą i
          doświadczeniem, lecz także empatią.
        </p>
      </>
    ),
    price: 80,
    imageUrl: require('../uploads/Offer/therapy_5.jpg'),
    time: 50,
  },
  {
    id: 1,
    name: 'Pomoc psychologiczna',
    summary:
      'Pomoc psychologiczna, docelowo skierowane do osób potrzebujących porady w kwestiach rodzinnych, małżeńskich, wychowawczych, zawodowych, związanych z trudnymi zmianami lub konkretnym okresem życia.',
    description: (
      <>
        <p>
          Pomoc psychologiczna, docelowo skierowane do osób potrzebujących
          porady w kwestiach rodzinnych, małżeńskich, wychowawczych, zawodowych,
          związanych z trudnymi zmianami lub konkretnym okresem życia.
        </p>

        <div style={{ marginBottom: '1rem' }}>
          <Grid container style={{ marginBottom: '20px' }}>
            <h4>Przykładowe wskazania do konsultacji psychologicznej to:</h4>
          </Grid>
          <ListComponent
            icon={null}
            key={3}
            list={[
              'Przeżywanie stresu, zaburzeń lękowych, problemów ze snem',
              'Utrzymujący się zły nastrój',
              'Problemy z kontrolowaniem emocji',
              'Problemy adaptacyjne i związane z relacjami interpersonalnymi',
              'Problemy wychowawcze i rozwojowe u dzieci - nieprawidłowe tempo rozwoju emocjonalnego, problemy z nauką, zachowania agresywne, brak samodzielności',
              'Dylematy zawodowe, wypalenie, chęć wprowadzenia zmian, brak równowagi między pracą a życiem osobistym',
              'Kłopoty rodzinne o różnym charakterze - trudności komunikacyjne, konflikty, uzależnienia, choroby przewlekłe lub niepełnosprawność',
              'Zaburzenia psychosomatyczne, a więc objawy lub choroby, które pojawiają się pod wpływem uwarunkowań psychicznych, np. chronicznego stresu',
              'Trudności z określeniem problemu, który odczuwalny jest jednak jako obciążenie emocjonalne',
            ]}
          />
        </div>
        <Grid container style={{ marginBottom: '20px' }}>
          <h4>Jak przebiega pomoc psychologiczna?</h4>
        </Grid>
        <p>
          Konsultacja psychologiczna polega na rozmowie z psychologiem,
          dotyczącej przyczyny zgłoszenia, przeżywanych trudności, ewentualnych
          objawów zaburzeń psychicznych, a także obszarów życia, w których
          chcesz wprowadzić zmiany. Psycholog może także zadać Ci pytania
          dotyczące Twojej historii życia, dotychczasowych doświadczeń oraz
          relacji z innymi, by móc lepiej zrozumieć przyczyny przeżywanych przez
          Ciebie trudności. By zadecydować o najskuteczniejszej formie pomocy,
          konieczne zwykle są 3 spotkania konsultacyjne, jedno spotkanie trwa 50
          minut. W trakcie konsultacji wspólnie z psychologiem możesz zastanowić
          się, czy potrzebne będzie Ci dalsze wsparcie, a także jaki rodzaj
          oddziaływań będzie dla Ciebie najlepszy. Psycholog może także
          skierować do innego specjalisty np. neurologa, psychiatry lub
          endokrynologa.
        </p>
      </>
    ),
    price: 180,
    imageUrl: require('../uploads/Offer/therapy_7.jpg'),
    time: 50,
  },
  {
    id: 9,
    name: 'Pomoc pedagogiczna dla rodziców',
    summary:
      'Jest to forma pomocy rodzicom, którzy chcieliby pozytywnie wpływać na rozwój swojego dziecka lub doświadczają problemów wychowawczych.',
    description: (
      <>
        <p>
          Jest to forma pomocy rodzicom, którzy chcieliby pozytywnie wpływać na
          rozwój swojego dziecka lub doświadczają problemów wychowawczych.
          Pedagog pomoże zwrócić uwagę na najważniejsze kwestie dotyczące
          wychowania, podpowie jak postępować z dzieckiem nadpobudliwym czy
          nieśmiałym, dzieckiem mającym problemy w nauce lub dzieckiem zdolnym.
        </p>

        <div style={{ marginBottom: '1rem' }}>
          <Grid container style={{ marginBottom: '20px' }}>
            <h4>Porady i pomoc obejmują:</h4>
          </Grid>
          <ListComponent
            icon={null}
            key={4}
            list={[
              'Trudności wychowawcze.',
              'Mediacje i interwencje.',
              'Porady dla rodziców.',
              'Poszukiwanie sensu.',
            ]}
          />
        </div>
      </>
    ),
    price: 180,
    imageUrl: require('../uploads/Offer/therapy_6.jpg'),
    time: 50,
  },
  {
    id: 8,
    name: 'Pomoc pedagogiczna dla dzieci i młodzieży',
    summary:
      'Głównym zadaniem pedagoga jest wsparcie rozwoju emocjonalnego, społecznego, intelektualnego i moralnego dziecka oraz tworzenie środowiska edukacyjnego sprzyjającego zdobywaniu wiedzy i nabywaniu umiejętności w poczuciu komfortu i bezpieczeństwa.',
    description: (
      <>
        <p>
          Głównym zadaniem pedagoga jest wsparcie rozwoju emocjonalnego,
          społecznego, intelektualnego i moralnego dziecka oraz tworzenie
          środowiska edukacyjnego sprzyjającego zdobywaniu wiedzy i nabywaniu
          umiejętności w poczuciu komfortu i bezpieczeństwa. Wiedząc, kto to
          jest pedagog, warto zaznaczyć, że ten specjalista jest otwarty na
          różnorodne problemy dzieci i młodzieży, którym może pomóc dzięki swoim
          kwalifikacjom, doświadczeniu, znajomości rozmaitych metod i technik
          pracy. Tym, czym zajmuje się pedagog, jest wsparcie i rozwiązywanie
          trudności, które mogą wpływać na funkcjonowanie dziecka w szkole, jak
          i poza nią.
        </p>

        <div style={{ marginBottom: '1rem' }}>
          <Grid container style={{ marginBottom: '20px' }}>
            <h4>
              Niektóre z najczęstszych problemów, z którymi dzieci i młodzież
              zwracają się do pedagoga, dotyczą:
            </h4>
          </Grid>
          <ListComponent
            icon={null}
            key={5}
            list={[
              'Samopoczucia emocjonalnego i psychologicznego.',
              'Relacji z rówieśnikami, rodzeństwem, nauczycielami czy rodzicami.',
              'Radzenia sobie z emocjami: agresją, gniewem czy złością.',
              'Adaptacji, przystosowania się do nowego środowiska szkolnego czy zmian życiowych.',
            ]}
          />
        </div>
        <p>
          Powyższe symptomy i zachowania powinny być sygnałem do tego, kiedy z
          dzieckiem zwrócić się do pedagoga. Pedagog może być pierwszym ogniwem
          w dalszej pracy nad problemami dziecka. To oznacza, że jeśli będzie to
          konieczne i sytuacja szczególnie zaniepokoi pedagoga, może on
          zasugerować opiekunom dziecka zgłoszenie się na psychoterapię, do
          psychologa lub innego specjalisty.
        </p>
      </>
    ),
    price: 180,
    imageUrl: require('../uploads/Offer/therapy_1.jpg'),
    time: 50,
  },
  {
    id: 2,
    name: 'Pomoc psychologiczna dla dzieci od 7 roku życia',
    summary:
      'W AMED CENTRUM DOBRYCH ZMIAN wierzymy, że swoimi trudnymi i niezrozumiałymi dla rodziców zachowaniami dziecko próbuje pokazać im, że doświadcza czegoś trudnego, że z czymś nie umie sobie poradzić i potrzebuje wsparcia.',
    description: (
      <>
        <p>
          W AMED CENTRUM DOBRYCH ZMIAN wierzymy, że swoimi trudnymi i
          niezrozumiałymi dla rodziców zachowaniami dziecko próbuje pokazać im,
          że doświadcza czegoś trudnego, że z czymś nie umie sobie poradzić i
          potrzebuje wsparcia. W trakcie spotkań konsultacyjnych psycholog stara
          się pomóc rodzicom w zrozumieniu, co takiego dzieje się w życiu ich
          dziecka oraz co mogą zrobić, by być dla niego wsparciem.
        </p>
        <p>
          Na konsultację z psychologiem dzieci i młodzieży zapraszamy rodziców,
          których niepokoją zachowania lub emocje ich dzieci, a także tych,
          których dzieci mają trudności szkole lub w relacjach z rówieśnikami.
          Zadane zostanie Wam wiele pytań, by psycholog mógł zrozumieć sytuację
          Waszego dziecka, a następnie wspólnie będziecie mogli podjąć decyzję,
          dotyczącą dalszych działań. Psycholog doradzi, czy konieczne jest
          wykonanie diagnozy, dalsza terapia albo inny rodzaj wsparcia dla
          dziecka lub rodziców.
        </p>
        <p>
          Efektywna współpraca rodzica z terapeutą jest kluczowa dla sukcesu
          terapii dziecka.
        </p>

        <div style={{ marginBottom: '1rem' }}>
          <Grid container style={{ marginBottom: '20px' }}>
            <h4>Rodzica zapraszamy na pierwszą konsultację bez dziecka.</h4>
          </Grid>
          <Grid container style={{ marginBottom: '20px' }}>
            <h4>
              Kiedy warto wybrać się z dzieckiem na konsultację psychologiczną?
            </h4>
          </Grid>

          <ListComponent
            icon={null}
            key={6}
            list={[
              'Dziecko ma trudności w nauce, nie może się skupić w trakcie zajęć, jego wyniki w nauce nagle się obniżyły.',
              'Nastrój dziecka uległ zmianie w ostatnim czasie, dziecko jest smutne, miewa wybuchy złości, izoluje się od rodziny i rówieśników.',
              'Dziecko ma trudności w relacjach koleżeńskich, jest bardzo nieśmiałe, czuje się samotne.',
              'W domu pojawia się wiele konfliktów z udziałem dziecka.',
              'Dziecko boi się przebywać bez rodziców, niechętnie się od nich oddala, odmawia chodzenia do szkoły.',
              'Dziecko nadużywa komputera, tabletu, telefonu komórkowego.',
              'Dziecko nie radzi sobie z rozwodem rodziców.',
              'Nastolatek okalecza się.',
            ]}
          />
        </div>
        <Grid container style={{ marginBottom: '20px' }}>
          <h4>Jak przebiega konsultacja psychologiczna?</h4>
        </Grid>
        <p>
          By zadecydować o najskuteczniejszej formie pomocy dziecku, konieczne
          zwykle są 3-4 spotkania konsultacyjne.
        </p>
        <p>
          W trakcie pierwszego spotkania (z rodzicami), psycholog poprosi o
          opowiedzenie, na czym polega zgłaszana trudność. Następnie zada
          pytania, dotyczące rozwoju dziecka, jego funkcjonowania w domu i w
          szkole, sytuacji rodzinnej i relacji w rodzinie, a także specyfiki
          zgłaszanego problemu (np. kiedy po raz pierwszy zaobserwowano
          trudności, w jakich okolicznościach występują objawy).
        </p>
        <p>
          Kolejne wizyty to spotkania psychologa z samym dzieckiem lub
          nastolatkiem. Przebiegają one w przyjaznej dziecku atmosferze – zwykle
          jest to zabawa, połączona z rozmową. Terapeuta pozostaje stale uważny
          na sygnały wysyłane przez dziecko i prowadzi spotkanie tak, by nie
          poczuło się ono zagrożone lub oceniane.
        </p>
      </>
    ),
    price: 180,
    imageUrl: require('../uploads/Offer/therapy_11.jpg'),
    time: 50,
  },
  {
    id: 3,
    name: 'Psychoterapia indywidualna',
    summary:
      'Pomoc psychologiczna w formie psychoedukacji. Na psychoterapię indywidualną zapraszamy wszystkie osoby, które chcą zadbać o swój rozwój i lepiej poznać siebie, a także te, które borykają się z problemami natury psychicznej lub kryzysem życiowym.',
    description: (
      <>
        <p>
          Pomoc psychologiczna w formie psychoedukacji. Na psychoterapię
          indywidualną zapraszamy wszystkie osoby, które chcą zadbać o swój
          rozwój i lepiej poznać siebie, a także te, które borykają się z
          problemami natury psychicznej lub kryzysem życiowym. Prowadzimy
          psychoterapię w podejściu: psychodynamicznym i integratywnym,
          łączącymm różne metody i nurty. Jeśli masz wątpliwości, czy potrzebna
          jest Ci psychoterapia, zapraszamy na 2-3 spotkania konsultacyjne, na
          których wspólnie z terapeutą zastanowisz się, jakie działania możesz
          podjąć.
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <Grid container style={{ marginBottom: '20px' }}>
            <h4>Główne cechy terapii psychodynamicznej to:</h4>
          </Grid>
          <ListComponent
            icon={null}
            key={7}
            list={[
              'Skupienie na przeszłości i nieświadomych procesach: Terapia psychodynamiczna skupia się na zrozumieniu wpływu przeszłych doświadczeń, w tym wczesnych relacji z rodzicami, na teraźniejsze zachowanie i myślenie.',
              'Długoterminowe podejście: Terapia psychodynamiczna jest zazwyczaj długoterminowym procesem, który wymaga czasu na zgłębienie i zrozumienie głęboko zakorzenionych problemów.',
              'Analiza wolnych skojarzeń: Terapeuci psychodynamiczni często korzystają z analizy wolnych skojarzeń, aby zgłębić nieświadome procesy pacjenta.',
              // 'Depresja.',
              // 'Kryzysy emocjonalne.',
            ]}
          />
        </div>
        <div style={{ marginBottom: '1rem' }}>
          <Grid container style={{ marginBottom: '20px' }}>
            <h4>Główne cechy terapii integratywnej to:</h4>
          </Grid>
          <ListComponent
            icon={null}
            key={7}
            list={[
              'Korzysta z technik stosowanych w różnych podejściach terapeutycznych.',
              'Do najczęściej stosowanych metod należą techniki poznawczo-behawioralne (CBT), psychodynamiczne, humanistyczne, a także elementy terapii Gestalt, terapii systemowej oraz terapii narracyjnej.',
              'Analiza wolnych skojarzeń: Terapeuci psychodynamiczni często korzystają z analizy wolnych skojarzeń, aby zgłębić nieświadome procesy pacjenta.',
              // 'Depresja.',
              // 'Kryzysy emocjonalne.',
            ]}
          />
        </div>
        <Grid container style={{ marginBottom: '20px' }}>
          {/* <h4>Kto może prowadzić psychoterapię?</h4> */}
        </Grid>

        <p>
          W AMED CENTRUM DOBRYCH ZMIAN psychoterapię prowadzą wyłącznie
          psychoterapeuci z pełnymi uprawnieniami oraz doświadczeniem w pracy z
          pacjentami.
        </p>
        <p>
          Terapia psychodynamiczna może być bardziej odpowiednia dla osób, które
          chcą zgłębić nieświadome przyczyny swoich problemów emocjonalnych i
          relacyjnych oraz rozwiązać długotrwałe konflikty.
        </p>
        <p>
          Wybór psychoterapii integratywnej wiąże się z wieloma zaletami, które
          czynią ją atrakcyjną opcją dla osób poszukujących wsparcia
          psychologicznego. Jednym z głównych atutów tego podejścia jest jego
          elastyczność i dostosowanie do indywidualnych potrzeb pacjenta.
        </p>
      </>
    ),
    price: 180,
    imageUrl: require('../uploads/Offer/therapy_2.jpg'),
    time: 50,
  },
  {
    id: 6,
    name: 'Pomoc psychologiczno-pedagogiczna dla rodzin',
    summary:
      'Pomoc psychologiczno - pedagogiczna dla rodzin to proces, w którym spotykacie się regularnie z terapeutą aby pracować nad swoimi trudnościami emocjonalnymi, psychicznymi czy życiowymi wyzwaniami. Jest przeznaczona dla rodzin, które doświadczają trudności w komunikacji, konfliktów lub innych problemów, które wpływają na relacje między członkami rodziny. Jest to forma psychoedukacji rodzin',
    description: (
      <>
        <p>
          Terapia rodzinna to proces, w którym spotykacie się regularnie z
          terapeutą, aby pracować nad swoimi trudnościami emocjonalnymi,
          psychicznymi czy życiowymi wyzwaniami. Jest przeznaczona dla rodzin,
          które doświadczają trudności w komunikacji, konfliktów lub innych
          problemów, które wpływają na relacje między członkami rodziny.
        </p>

        <div style={{ marginBottom: '1rem' }}>
          <Grid container style={{ marginBottom: '20px' }}>
            <h4>Uwaga:</h4>
          </Grid>
          <ListComponent
            icon={NotificationImportantIcon}
            key={2}
            list={[
              'Świadczona przez nas usługa nie jest psychoterapią.',
              'Konsultacje terapeutyczne dla rodzin prowadzi pedagog który ukończył studia w zakresie terapii rodzin i par.',
            ]}
          />
        </div>
      </>
    ),
    price: 240,
    imageUrl: require('../uploads/Offer/therapy_4.jpg'),
    time: 90,
  },
  {
    id: 4,
    name: 'Psychoterapia nastolatków',
    summary:
      'Pomoc psychologiczna w formie psychoedukacji. Psychoterapia indywidualna dla młodzieży to cykl spotkań z psychoterapeutą,  pozwalających młodej osobie poznać istotę problemu, z którym się zgłasza.',
    description: (
      <>
        <p>
          Pomoc psychologiczna w formie psychoedukacji. Psychoterapia
          indywidualna dla młodzieży to cykl spotkań z psychoterapeutą,
          pozwalających młodej osobie poznać istotę problemu, z którym się
          zgłasza. Psychoterapię indywidualną polecamy osobom, które zmagają się
          z przygnębieniem, depresją, stresem, lękami, zaburzeniami odżywiania,
          zaburzeniami snu, trudnościami w nawiązywaniu relacji z innymi ludźmi
          czy cierpią z powodu utraty bliskiej osoby i nie potrafią poradzić
          sobie z tą stratą. Powodem zgłoszenia może być również przeżywany
          kryzys wartości, poczucie braku sensu życia, doświadczenie uczucia
          pustki w relacjach z innym.
        </p>

        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            icon={NotificationImportantIcon}
            key={2}
            list={['Od 15 roku życia']}
          />
          <ListComponent
            icon={PsychologyIcon}
            key={2}
            list={['Nurt integratywny']}
          />
        </div>
      </>
    ),
    price: 180,
    imageUrl: require('../uploads/Rooms/Amed_17.JPEG'),
    time: 60,
  },
  {
    id: 5,
    name: 'Psychoterapia par',
    summary:
      'Pomoc psychologiczna w formie psychoedukacji. Terapia par i małżeństw jest przeznaczona dla osób, które doświadczają trudności w swoim związku lub małżeństwie.',
    description: (
      <>
        <p>
          Pomoc psychologiczna w formie psychoedukacji. Terapia par i małżeństw
          jest przeznaczona dla osób, które doświadczają trudności w swoim
          związku lub małżeństwie. Może to obejmować problemy z komunikacją,
          niezgodność co do uznawanych wartości, brak zrozumienia i zaufania,
          zdrady, konflikty, problemy intymne czy trudności w kwestiach
          wychowywania dzieci czy zarządzania finansami. Terapia par jest
          skierowana zarówno do par małżeńskich, jak i par partnerskich,
          niezależnie od długości trwania związku.
        </p>
        <div style={{ marginBottom: '1rem' }}>
          <ListComponent
            icon={PsychologyIcon}
            key={2}
            list={['Nurt integratywny']}
          />
        </div>
      </>
    ),
    price: 240,
    imageUrl: require('../uploads/Offer/therapy_3.jpg'),
    time: 90,
  },
];
